var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('base-card',[_c('v-stepper',[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"complete":_vm.$store.state.invoice.listInvoiceDetail[0]['Estado'] == 'P',"step":"P","color":"green"}},[_vm._v(" PENDIENTE ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"R","complete":_vm.$store.state.invoice.listInvoiceDetail[0]['Estado'] == 'RECIBIDA',"color":"green"}},[_vm._v(" RECIBIDA ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"complete":_vm.$store.state.invoice.listInvoiceDetail[0]['Estado'] == 'AUTORIZADO',"step":"A","color":"green"}},[_vm._v(" AUTORIZADO ")]),_c('v-divider'),_c('v-stepper-step',{attrs:{"step":"N","complete":_vm.$store.state.invoice.listInvoiceDetail[0]['Estado'] == 'NOTIFICADO',"color":"green"}},[_vm._v(" NOTIFICADA ")])],1)],1),_c('br'),_c('v-card-title',[_c('router-link',{attrs:{"to":"/app/invoices/reportInvoices"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"red darken-2","right":""}},[_vm._v(" mdi-arrow-left-bold ")])],1),_vm._v(" Detalle de Factura Electrónica [ "+_vm._s(_vm.$store.state.invoice.listInvoiceDetail[0]["ConsecutivoDocumento"])+" ] ")],1),_c('br'),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"footer-props":{
            'items-per-page-text': 'filas por pagina',
          },"headers":_vm.headers,"items":_vm.$store.state.invoice.listInvoiceDetail,"search":_vm.search},scopedSlots:_vm._u([{key:"item.Estado",fn:function(ref){
          var item = ref.item;
return [(item.Estado === 'NO_AUTORIZADO' || item.Estado === 'DEVUELTA')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"red darken-2","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Estado))])]):(item.Estado === 'P' )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"info darken-2","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-minus ")])]}}],null,true)},[_c('span',[_vm._v("PENDIENTE")])]):_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"success darken-2","right":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-file-check ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.Estado))])])]}},{key:"item.Mensaje",fn:function(ref){
          var item = ref.item;
return [_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"success darken-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-cloud-braces ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"dark":""}},[_vm._v(" Mensaje de respuesta - Factura - "+_vm._s(item.ConsecutivoDocumento)+" ")]),_c('v-card-text',[_c('div',{staticClass:"text-h6"},[_c('json-viewer',{attrs:{"value":JSON.parse(item.Mensaje),"copyable":"true","preview-mode":"true"},scopedSlots:_vm._u([{key:"copy",fn:function(){return [_vm._v(" Copiar "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")])]},proxy:true}],null,true)})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cerrar ")])],1)],1)]}}],null,true)})]}},{key:"item.Json",fn:function(ref){
          var item = ref.item;
return [_c('v-dialog',{attrs:{"transition":"dialog-bottom-transition","max-width":"700"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"success darken-4"}},'v-icon',attrs,false),on),[_vm._v(" mdi-code-json ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{staticStyle:{"background-color":"rgb(204 33 40)","color":"white !important"},attrs:{"dark":""}},[_vm._v(" Json enviado a SRI - Factura - "+_vm._s(item.ConsecutivoDocumento)+" ")]),_c('v-card-text',[_c('div',{staticClass:"text-h6"},[_c('json-viewer',{attrs:{"value":JSON.parse(item.Json),"copyable":"true"},scopedSlots:_vm._u([{key:"copy",fn:function(){return [_vm._v(" Copiar "),_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"info darken-3"}},[_vm._v(" mdi-content-copy ")])]},proxy:true}],null,true)})],1)]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v(" Cerrar ")])],1)],1)]}}],null,true)})]}},{key:"item.UrlXMLHiopos",fn:function(ref){
          var item = ref.item;
return [(item.Estado !== 'P')?_c('v-icon',{staticClass:"mr-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"brown darken-3"},on:{"click":function($event){return _vm.getDataFile(item.UrlXMLHiopos, 'UrlXMLHiopos [' + _vm.ConsecutivoDocumento + ']')}}},[_vm._v(" mdi-folder-zip ")]):_vm._e()]}}],null,true)})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }