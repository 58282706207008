<template>
  <v-row>
    <v-col cols="12">
      <base-card>
        <v-stepper>
          <v-stepper-header>
            <v-stepper-step
              :complete="$store.state.invoice.listInvoiceDetail[0]['Estado'] == 'P'"
              step="P"
              color="green"
            >
              PENDIENTE
            </v-stepper-step>
            <v-divider />

            <v-stepper-step
              step="R"
              :complete="$store.state.invoice.listInvoiceDetail[0]['Estado'] == 'RECIBIDA'"
              color="green"
            >
              RECIBIDA
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              :complete="$store.state.invoice.listInvoiceDetail[0]['Estado'] == 'AUTORIZADO'"
              step="A"
              color="green"
            >
              AUTORIZADO
            </v-stepper-step>

            <v-divider />

            <v-stepper-step
              step="N"
              :complete="$store.state.invoice.listInvoiceDetail[0]['Estado'] == 'NOTIFICADO'"
              color="green"
            >
              NOTIFICADA
            </v-stepper-step>
          </v-stepper-header>
        </v-stepper><br>

        <v-card-title>
          <router-link
            :to="`/app/invoices/reportInvoices`"
          >
            <v-icon
              class="mr-2"
              color="red darken-2"
              right
            >
              mdi-arrow-left-bold
            </v-icon>
          </router-link>
          Detalle de Factura Electrónica [ {{ $store.state.invoice.listInvoiceDetail[0]["ConsecutivoDocumento"] }} ]
        </v-card-title>
        <br>
        <v-card-text>
          <v-data-table
            :footer-props="{
              'items-per-page-text': 'filas por pagina',
            }"
            :headers="headers"
            :items="$store.state.invoice.listInvoiceDetail"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.Estado`]="{ item }">
              <v-tooltip
                v-if="item.Estado === 'NO_AUTORIZADO' || item.Estado === 'DEVUELTA'"
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="red darken-2"
                    right
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-file-alert
                  </v-icon>
                </template>
                <span>{{ item.Estado }}</span>
              </v-tooltip>
              <v-tooltip
                v-else-if="item.Estado === 'P' "
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="info darken-2"
                    right
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-file-minus
                  </v-icon>
                </template>
                <span>PENDIENTE</span>
              </v-tooltip>
              <v-tooltip
                v-else
                bottom
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    class="mr-2"
                    color="success darken-2"
                    right
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-file-check
                  </v-icon>
                </template>
                <span>{{ item.Estado }}</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.Mensaje`]="{ item }">
              <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="cursor: pointer"
                    color="success darken-4"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-cloud-braces
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      style="background-color:rgb(204 33 40);color:white !important"
                      dark
                    >
                      Mensaje de respuesta - Factura - {{ item.ConsecutivoDocumento }}
                    </v-toolbar>
                    <v-card-text>
                      <div class="text-h6">
                        <json-viewer
                          :value="JSON.parse(item.Mensaje)"
                          copyable="true"
                          preview-mode="true"
                        >
                          <template v-slot:copy>
                            Copiar
                            <v-icon
                              style="cursor: pointer"
                              color="info darken-3"
                              class="mr-2"
                            >
                              mdi-content-copy
                            </v-icon>
                          </template>
                        </json-viewer>
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="dialog.value = false"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>
            <template v-slot:[`item.Json`]="{ item }">
              <v-dialog
                transition="dialog-bottom-transition"
                max-width="700"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="cursor: pointer"
                    color="success darken-4"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-code-json
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      style="background-color:rgb(204 33 40);color:white !important"
                      dark
                    >
                      Json enviado a SRI - Factura - {{ item.ConsecutivoDocumento }}
                    </v-toolbar>
                    <v-card-text>
                      <div class="text-h6">
                        <json-viewer
                          :value="JSON.parse(item.Json)"
                          copyable="true"
                        >
                          <template v-slot:copy>
                            Copiar
                            <v-icon
                              style="cursor: pointer"
                              color="info darken-3"
                              class="mr-2"
                            >
                              mdi-content-copy
                            </v-icon>
                          </template>
                        </json-viewer>
                      </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="dialog.value = false"
                      >
                        Cerrar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>
            <template v-slot:[`item.UrlXMLHiopos`]="{ item }">
              <v-icon
                v-if="item.Estado !== 'P'"
                class="mr-2"
                color="brown darken-3"
                style="cursor: pointer"
                @click="getDataFile(item.UrlXMLHiopos, 'UrlXMLHiopos [' + ConsecutivoDocumento + ']')"
              >
                mdi-folder-zip
              </v-icon>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>
<script>
  import { mapState } from 'vuex'
  import { columnsRolesDetail } from '@/data/columnsRoles'

  export default {
    name: 'ShowInvoicesDetail',
    data () {
      return {
        progress: false,
        src: '',
        numPages: 0,
        page: 1,
        currentPage: 0,
        singleSelect: false,
        selected: [],
        search: '',
        size: 300,
        headers: columnsRolesDetail,

      }
    },
    computed: {
      ...mapState('invoice', [
        'listInvoiceDetail',
        'fileBase64',
        'fileName',
      ]),
    },
    watch: {
      fileBase64 () {
        console.log(this.fileName)
        if (this.fileName !== 'PDF') {
          this.downloadFile(this.fileName)
        }
      },
    },
    created () {
      this.getListInvoiceDetail()
    },
    methods: {
      async getListInvoiceDetail () {
        await this.$store.dispatch('invoice/getListInvoiceDetail', this.$route.query)
      },
      async getDataFile (file, name) {
        const datos = { url: file, nameFile: name }
        await this.$store.dispatch('invoice/getDataFile', datos)
      },
      downloadFile (name) {
        const linkSource = 'data:application/pdf;base64,' + this.fileBase64.Datos
        const downloadLink = document.createElement('a')
        const fileName = name + '.zip'
        downloadLink.href = linkSource
        downloadLink.download = fileName
        downloadLink.click()
      },

    },
  }
</script>

<style scoped>
</style>
